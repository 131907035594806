import {nextReduxCookieMiddleware} from 'next-redux-cookie-wrapper'

import {Api} from '../api'
import {attractionApi} from '../api/backend/attraction'
import {destinationApi} from '../api/backend/destination'
import {entityApi} from '../api/backend/entity'
import {homeApi} from '../api/backend/home'
import {languageApi} from '../api/backend/languages'
import {mediaApi} from '../api/backend/media'
import {mediaBundleApi} from '../api/backend/media-bundles'
import {ratingApi} from '../api/backend/rating'
import {searchApi} from '../api/backend/search'
import {spotApi} from '../api/backend/spot'
import {userApi} from '../api/backend/user'
import {paymentMethodsApi} from '../api/payment/payment-methods'
import {templateApi} from '../api/ticketing/template'

export default config => getDefaultMiddleware =>
  getDefaultMiddleware({
    thunk: {
      extraArgument: config
    }
  })
    .prepend(
      nextReduxCookieMiddleware({
        subtrees: ['user.initialsBackgroundColor']
      })
    )
    .concat(Api.middleware)
    .concat(mediaBundleApi.middleware)
    .concat(spotApi.middleware)
    .concat(templateApi.middleware)
    .concat(languageApi.middleware)
    .concat(searchApi.middleware)
    .concat(ratingApi.middleware)
    .concat(userApi.middleware)
    .concat(attractionApi.middleware)
    .concat(mediaApi.middleware)
    .concat(entityApi.middleware)
    .concat(paymentMethodsApi.middleware)
    .concat(homeApi.middleware)
    .concat(destinationApi.middleware)
