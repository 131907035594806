import {Api} from '../api'
import {attractionApi} from '../api/backend/attraction'
import {destinationApi} from '../api/backend/destination'
import {entityApi} from '../api/backend/entity'
import {homeApi} from '../api/backend/home'
import {languageApi} from '../api/backend/languages'
import {mediaApi} from '../api/backend/media'
import {mediaBundleApi} from '../api/backend/media-bundles'
import {ratingApi} from '../api/backend/rating'
import {searchApi} from '../api/backend/search'
import {spotApi} from '../api/backend/spot'
import {userApi} from '../api/backend/user'
import {paymentMethodsApi} from '../api/payment/payment-methods'
import {templateApi} from '../api/ticketing/template'
import userReducer from '../slices/user'

const rootReducer = {
  [Api.reducerPath]: Api.reducer,
  [mediaBundleApi.reducerPath]: mediaBundleApi.reducer,
  [spotApi.reducerPath]: spotApi.reducer,
  [templateApi.reducerPath]: templateApi.reducer,
  [languageApi.reducerPath]: languageApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [ratingApi.reducerPath]: ratingApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [attractionApi.reducerPath]: attractionApi.reducer,
  [mediaApi.reducerPath]: mediaApi.reducer,
  [entityApi.reducerPath]: entityApi.reducer,
  [paymentMethodsApi.reducerPath]: paymentMethodsApi.reducer,
  [homeApi.reducerPath]: homeApi.reducer,
  [destinationApi.reducerPath]: destinationApi.reducer,

  user: userReducer
}

export default rootReducer
